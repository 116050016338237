<template>
  <div class="container headerContainer">
    <div class="row g-2 g-lg-3 align-items-center">
      <div
        class="col col-sm-12 col-12 col-lg-6 photoSide"
        :style="{
          backgroundImage: `url('${server_url}/${data.image}')`,
        }"
      ></div>

      <div class="col col-sm-12 col-12 col-lg-6 pl-0 pr-0 textSide">
        <p v-if="lang == 'ka'" v-html="data.textKA" class="text pt-4"></p>
        <p v-else v-html="data.textEN" class="text pt-4"></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";

export default {
  data() {
    return {
      data: {},
      lang: "ka",
      server_url: env.server_url,
    };
  },
  mounted() {
    axios
      .get(`${env.host}/get/informations/partners`)
      .then((result) => {
        console.log(result);
        this.data = result.data.item;
      })

      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
* {
  color: #666666;
}
.row {
  padding: 0px;
  padding-right: 25px;
  margin: 0px;
}
.headerContainer {
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-width: 1400px;
}
.photoSide {
  height: 30rem;
  width: 100%;
  background-position: center center;

  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;

  text-align: center;
}
.textSide {
  align-items: center;
  display: flex;

  padding-left: 40px;
}
.text {
  font-size: 16px;
  padding-left: 40px;
}

.textSide p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 18;
  -webkit-box-orient: vertical;
}

.photoSide img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;

  text-align: center;
}
.titleSide {
  text-align: left;
  padding-left: 30px;
  height: 100%;
  margin: auto;
  align-items: center;
}

.titleSide p {
  font-size: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 20;
  -webkit-box-orient: vertical;
  color: #4d4d4c;
}
@media all and (max-width: 1500px) {
  .titleSide {
    padding-left: 20px !important;
    padding-right: 20px;
  }
}
@media all and (max-width: 991px) {
  .headerContainer .row {
    padding-right: 0px;
  }
  .photoSide {
    height: 50vw;
  }
  .valueListing {
    padding-top: 30px;
  }
  .titleSide {
    padding-left: 0px;
  }
  .textSide,
  .text {
    align-items: center;
    display: flex;

    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media all and (max-width: 575px) {
  .headerContainer .row {
    padding-right: 0px;
  }
  .titleSide {
    padding-left: 0px;
  }
  .photoSide {
    height: 14rem;
  }
}
</style>
