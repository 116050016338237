<template>
  <div class="containerLogos p-1">
    <div
      v-if="!isLoading"
      class="row row-cols-lg-4 ml-0 mr-0"
      style="width: 100%"
    >
      <div
        class="col col-sm-6 mt-4 col-12"
        v-for="item in items"
        :key="item.id"
      >
        <div>
          <div class="card holder" style="height: 10rem">
            <img
              class="zoooom"
              :src="server_url + '/' + item.image"
              alt="partners"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row row-cols-lg-4" style="width: 100%; margin: auto">
        <div
          class="col col-sm-6 mt-4 col-12"
          v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
          :key="item"
        >
          <b-skeleton-img
            no-aspect
            height="10rem"
            type="input"
          ></b-skeleton-img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
export default {
  data() {
    return {
      data: {},
      isLoading: false,
      items: [],
      server_url: env.server_url,
    };
  },
  mounted() {
    this.isLoading = true;
    axios
      .get(`${env.host}/get/partners/user`)
      .then((result) => {
        console.log(result);
        this.items = result.data.item;
        this.isLoading = false;
      })

      .catch((err) => console.log(err));
  },
};
</script>

<style scoped>
* {
  font-family: mtavruli;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a480b7a6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a480b7;
}
.card-body {
  padding: 0px;
}

.containerLogos {
  margin-top: 20px;

  width: 100%;
  max-width: 1400px;
  padding: 0px;
  overflow-y: scroll;
  max-height: 800px;
  padding-right: 10px;
}

.card {
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  padding: 0px;
  border-radius: 0px;
  align-items: center;
  border: none;
}

.card img {
  width: 100%;
  height: 100%;
}

.button.btn {
  padding: 0px;
}

.btn-primary {
  border-radius: 0px;
  background-color: #7e54939f;
  border-color: #7e5493;
  padding: 15px;
}
.withOut {
  height: 60px;
  padding: 0px;
  text-align: center;
}
.btn-primary:hover {
  background-color: #7e5493;
  border-color: #7e5493;
}
a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

.holder {
  overflow: hidden;
}

.zoooom {
  transition: all 0.2s ease-in-out;
}
.zoooom:hover {
  width: 105%;
  height: 100%;
}

@media all and (max-width: 991px) {
  .containerLogos {
    overflow-y: hidden;
    max-height: none;
  }
}
</style>
