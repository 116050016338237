<template>
  <div>
    <Navbar />
    <div class="partnersMian mb-4">
      <PagesPartnersHeader />
      <div class="conatiner">
        <Logos />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import PagesPartnersHeader from "./PagesPartnersHeader.vue";
import Navbar from "./../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "./../../../components/Footer/newFooter/Footer.vue";
import Logos from "./Logos.vue";
export default {
  name: "partners",
  components: {
    PagesPartnersHeader,
    Navbar,
    Footer,
    Logos,
  },
};
</script>

<style scoped>
.partnersMian {
  width: 100%;
  margin: auto;
  max-width: 1400px;
  min-height: calc(100vh - 190px);
}
</style>
